import * as React from 'react'
import PostListItem from './PostListItem'

export default function RecentPosts({ className = '', posts }) {
    if (!posts.length) return null

    return (
        <div className={className}>
            <h3 className="mb-3 text-2xl md:text-3xl font-light text-gray-700 leading-snug md:leading-tight">
                Articles les plus récents
            </h3>

            <ul className="flex flex-col space-y-3">
                {posts.map(post => (
                    <PostListItem post={post} key={post.uid} />
                ))}
            </ul>
        </div>
    )
}
