import * as React from 'react'
import { graphql, Link } from 'gatsby'
import * as prismicH from '@prismicio/helpers'
import { PrismicRichText } from '@prismicio/react'

import Layout from '../components/Layout'
import Seo from '../components/Seo'
import AdSlot from '../components/AdSlot'
import RecentPosts from '@/components/post/RecentPosts'
import PopularPosts from '@/components/post/PopularPosts'

const Post = ({ data, pageContext }) => {
    if (!data) return null

    const document = data.prismicPost?.data
    const settings = data.prismicGeneralSettings?.data
    const category = document.post_category
    const categoryName = category?.document?.data?.category_title?.text
    const categorySlug = category?.uid

    return (
        <Layout>
            <Seo
                title={
                    document.seo_title?.text ||
                    prismicH.asText(document.post_title?.richText)
                }
                description={document.seo_description?.text}
                ogImage={document.og_image?.url}
            />

            <div className="bg-gray-100">
                {/* Other Slot */}
                <AdSlot html={settings.other_slot?.text} />
            </div>

            <article className="grid lg:grid-cols-3 gap-16 mx-auto px-6 pt-12 max-w-7xl">
                <div className="lg:col-span-2">
                    <header className="mb-4">
                        <h1 className="mb-2 text-3xl md:text-4xl font-light text-gray-700 leading-snug md:leading-tight">
                            {prismicH.asText(document.post_title?.richText)}
                        </h1>

                        {categoryName && categorySlug ? (
                            <Link
                                className="inline-block mb-2 px-2 py-1 text-[10px] font-medium text-white uppercase bg-primary"
                                to={`/blog/categorie/${categorySlug}`}
                                title={categoryName}
                            >
                                {categoryName}
                            </Link>
                        ) : null}
                    </header>
                    <div className="pb-20 prose prose-lg max-w-none text-gray-600">
                        <div className="flex flex-col px-6 text-base bg-gray-100">
                            <PrismicRichText
                                field={settings.blog_post_before_text?.richText}
                            />
                        </div>

                        <PrismicRichText
                            field={document.post_content?.richText}
                            components={{
                                preformatted: ({ node, key }) => (
                                    <div
                                        key={key}
                                        dangerouslySetInnerHTML={{
                                            __html: node.text
                                        }}
                                    />
                                )
                            }}
                        />

                        <div className="flex flex-col mt-10 px-6 text-base bg-gray-100">
                            <PrismicRichText
                                field={settings.blog_post_after_text?.richText}
                            />
                        </div>
                    </div>
                </div>

                <aside>
                    <RecentPosts className="mb-8" posts={data.posts?.nodes} />
                    <PopularPosts
                        posts={data.topPosts?.nodes}
                        tops={pageContext.tops}
                    />
                </aside>
            </article>

            {settings.blog_post_after_slot?.text ? (
                <div className="bg-gray-100">
                    {/* Other Slot */}
                    <AdSlot html={settings.blog_post_after_slot?.text} />
                </div>
            ) : null}
        </Layout>
    )
}

export default Post

export const query = graphql`
    query PostQuery($uid: String!, $tops_uids: [String]) {
        prismicGeneralSettings {
            data {
                blog_post_before_text {
                    richText
                }
                blog_post_after_text {
                    richText
                }
                blog_post_after_slot {
                    text
                }
                other_slot {
                    text
                }
            }
        }
        prismicPost(uid: { eq: $uid }) {
            data {
                post_title {
                    richText
                }
                seo_title {
                    text
                }
                seo_description {
                    text
                }
                og_image {
                    url
                }
                post_content {
                    richText
                }
                post_category {
                    uid
                    document {
                        ... on PrismicBlogCategory {
                            id
                            data {
                                category_title {
                                    text
                                }
                            }
                        }
                    }
                }
            }
        }
        posts: allPrismicPost(
            sort: { fields: first_publication_date, order: DESC }
            limit: 4
        ) {
            nodes {
                uid
                data {
                    post_title_alt {
                        text
                    }
                    post_title {
                        text
                    }
                    post_link {
                        link_type
                        type
                        uid
                        url
                    }
                    post_category {
                        uid
                        document {
                            ... on PrismicBlogCategory {
                                id
                                data {
                                    category_title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    og_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
        topPosts: allPrismicPost(
            filter: { uid: { in: $tops_uids, ne: $uid } }
        ) {
            nodes {
                uid
                data {
                    post_title_alt {
                        text
                    }
                    post_title {
                        text
                    }
                    post_link {
                        link_type
                        type
                        uid
                        url
                    }
                    post_category {
                        uid
                        document {
                            ... on PrismicBlogCategory {
                                id
                                data {
                                    category_title {
                                        text
                                    }
                                }
                            }
                        }
                    }
                    og_image {
                        localFile {
                            childImageSharp {
                                gatsbyImageData(
                                    quality: 100
                                    placeholder: BLURRED
                                )
                            }
                        }
                    }
                }
            }
        }
    }
`
