import * as React from 'react'
import _ from 'lodash'
import PostListItem from './PostListItem'

export default function PopularPosts({ posts, tops }) {
    const sortedTops = _.keys(
        _.fromPairs(_.sortBy(_.toPairs(tops), 1).reverse())
    )

    const sortedPosts = _.take(
        _.sortBy(posts, o => sortedTops.indexOf(o.uid)),
        4
    )

    return (
        <div>
            <h3 className="mb-3 text-2xl md:text-3xl font-light text-gray-700 leading-snug md:leading-tight">
                Articles les plus populaires
            </h3>

            <ul className="flex flex-col space-y-3">
                {sortedPosts.map(post => (
                    <PostListItem post={post} key={post.uid} />
                ))}
            </ul>
        </div>
    )
}
