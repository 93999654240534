import * as React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, getImage } from 'gatsby-plugin-image'

export default function PostListItem({ post }) {
    const image = getImage(post.data?.og_image?.localFile)
    const category = post.data?.post_category
    const categoryName = category?.document?.data?.category_title?.text
    const categorySlug = category?.uid

    return (
        <li className="pt-2 border-t border-gray-200">
            <Link
                className="block mb-1 hover:text-primary transition"
                to={`/blog/${post.uid}`}
                title={
                    post.data?.post_title_alt?.text ||
                    post.data?.post_title?.text
                }
            >
                {image ? (
                    <GatsbyImage
                        className="mb-2 w-full"
                        image={image}
                        alt={
                            post.data?.post_title_alt?.text ||
                            post.data?.post_title?.text
                        }
                    />
                ) : null}
                <span className="block text-sm font-bold">
                    {post.data?.post_title_alt?.text ||
                        post.data?.post_title?.text}
                </span>
            </Link>
            {categoryName && categorySlug ? (
                <Link
                    className="inline-block mb-2 px-2 py-1 text-[10px] font-medium text-gray-600 uppercase bg-gray-200 hover:bg-gray-300 transition"
                    to={`/blog/categorie/${categorySlug}`}
                    title={categoryName}
                >
                    {categoryName}
                </Link>
            ) : null}
        </li>
    )
}
